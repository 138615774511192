import React, { useEffect, useContext } from "react"
import styled, {keyframes} from "styled-components"
import AppContext from "../components/Context"

const moving = keyframes`
    0% {transform: translateX(0) translateY(0)}
    25% {transform: translateX(-50%) translateY(100%)}
    50% {transform: translateX(-100%) translateY(0)}
    75% {transform: translateX(-50%) translateY(100%)}
    100% {transform: translateX(0) translateY(0)}
`

const Bubble = styled.div`
    width: 50px;
    height: 50px;
    background: #FFE8DB;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transition: left 0.3s ease, background 0.3s ease, opacity 0.3s ease;
    will-change: top;
    opacity: 0;
    @media only screen and (min-width: 700px) {
        left: 50%;
        position: fixed;
        animation: ${moving} 5s infinite ease-in-out;
    }
    &.move {
        left: 50%;
        position: fixed;
        opacity: 1;
        animation: ${moving} 5s infinite ease-in-out;
    }
    &.advanced {
        background: #FFF9DB;
        z-index: 1;
    }
    &.comptes {
        background: #F5FAFF;
    }
`

const Bubble2 = styled(Bubble)`
    width: 70px;
    height: 70px;
    @media only screen and (min-width: 700px) {
        left: 20%;
        top: 60%;
        animation: ${moving} 5.8s 0.5s infinite ease-in-out;
    }
    &.move {
        top: 60%;
        left: 20%;
        animation: ${moving} 5.8s 0.5s infinite ease-in-out;
    }
`

const Bubble3 = styled(Bubble)`
    width: 90px;
    height: 90px;
    top: 45%;
    @media only screen and (min-width: 700px) {
        left: 80%;
        top: 70%;
        animation: ${moving} 5.3s 0.8s infinite ease-in-out;
    }
    &.move {
        top: 70%;
        left: 80%;
        animation: ${moving} 5.3s 0.8s infinite ease-in-out;
    }
`

const Bubble4 = styled(Bubble)`
    width: 20px;
    height: 20px;
    @media only screen and (min-width: 700px) {
        left: 10%;
        top: 80%;
        animation: ${moving} 4.3s 0.3s infinite ease-in-out;
    }
    &.move {
        top: 80%;
        left: 10%;
        animation: ${moving} 4.3s 0.3s infinite ease-in-out;
    }
`

const Bubbles = () => {
    const {isMobile} = useContext(AppContext)

    const handleScroll = (_e) => {
        const $bubbles = document.querySelectorAll(".mobileBubble")
        const $trigger = document.querySelector(".trigger")
        const $intermediate = document.querySelector("#intermediate")
        const $advanced = document.querySelector("#advanced")
        const $comptes = document.querySelector("#grands-comptes")
        const top = $intermediate.getBoundingClientRect().top
        $bubbles.forEach(($bubble) => {
            if((isMobile && top < 0 && $trigger.getBoundingClientRect().bottom < (window.innerHeight / 1.5)) || (!isMobile && $trigger.getBoundingClientRect().top < (window.innerHeight / 1.5))) {
                $bubble.classList.add("move")
            } else if ($bubble.classList.contains("move")) {
                $bubble.classList.remove("move")
            }
            if($advanced.getBoundingClientRect().top < $bubble.getBoundingClientRect().bottom) {
                $bubble.classList.add("advanced")
            } else if ($advanced.getBoundingClientRect().top > $bubble.getBoundingClientRect().bottom && $bubble.classList.contains("advanced")) {
                $bubble.classList.remove("advanced")
            }
            if($comptes.getBoundingClientRect().top < $bubble.getBoundingClientRect().bottom && !$bubble.classList.contains("comptes")) {
                $bubble.classList.add("comptes")
            } else if ($comptes.getBoundingClientRect().top > $bubble.getBoundingClientRect().bottom && $bubble.classList.contains("comptes")) {
                $bubble.classList.remove("comptes")
            }
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {window.removeEventListener("scroll", handleScroll)}
    })

    return (
        <>
            <Bubble className="mobileBubble" />
            <Bubble2 className="mobileBubble" />
            <Bubble3 className="mobileBubble" />
            <Bubble4 className="mobileBubble" />
        </>
    )
}

export default Bubbles