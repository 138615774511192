import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Tilt from "react-tilt"
import AppContext from "../components/Context"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cadre from "../components/Cadre"
import MediumArticles from "../components/MediumArticles"
import BackgroundBlob from "../components/Blob"
import Bubbles from "../components/Bubbles"

import { BlueText, Title, Text, SectionTitle } from "../elements/Text"
import { MainContainer, Container, Row } from "../elements/Container"
import { family, size } from "../elements/font"
import colors from "../elements/color"

const TableTitle = styled.p`
  width: 100%;
  font-size: 1.4vw;
  font-family: ${family.Archivo};
  text-align: center;
  margin-bottom: 30px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`

const TableImg = styled(Tilt)`
  width: 28%;
  height: 110px;
  transition: transform 0.1s ease;
  @media only screen and (max-width: 600px) {
    width: 25%;
    height: 90px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 800px) {
    width: 14%;
    height: 90px;
    margin-bottom: 0px;
  }
`

const TableText = styled.p`
  font-size: ${size.XXXS};
  line-height: 0.8rem;
  width: 22%;
  margin-bottom: 5px;
  z-index: 10;
  &:nth-child(1) {
    margin-left: 40px;
    width: 18%;
  }
  @media only screen and (max-width: 800px) {
    font-size: 10px;
    line-height: 15px;
    width: 23%;
    margin-bottom: 0;
  }
`

const RowTitle = styled.p`
  width: 100%;
  font-size: ${size.XXS};
  font-family: ${family.Archivo};
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: "";
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: 25px;
    left: 0;
    background: #00000050;
  }
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    margin-bottom: 5px;
    &::after {
      top: 20px;
    }
  }
`

const OfferTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  @media only screen and (max-width: 800px) {
    width: 400%;
    padding-right: 10vw;
  }
`

const Column = styled.div`
  width: 22%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`

const ProgressBar = styled.div`
  width: 60vw;
  margin: 0 auto;
  margin-top: 50px;
  height: 1px;
  background: #00000050;
  position: relative;
`

const Circle = styled.div`
  position: absolute;
  left: 0;
  background: #000000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: visible;
  padding: 0 5vw;
  @media only screen and (max-width: 800px) {
    overflow-x: scroll;
  }
`

const OfferDetail = styled.div`
  background: ${props => props.background};
  width: 100%;
  padding: 10vh 6vw;
  padding-top: 130px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${props => props.marginTop && "margin-top: 15vh;"}
  @media only screen and (max-width: 800px) {
    padding: 80px 6vw;
  }
`

const ImageEau = styled(Tilt)`
  width: 16%;
  @media only screen and (max-width: 800px) {
    width: 25%;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 5;
  }
`

const StepContainer = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10vh;
  z-index: 2;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 55px;
    height: 80vh;
  }
`

const StepColumn = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${family.Alata};
  position: relative;
  /* &:not(:nth-child(5))::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 70%;
    width: 88%;
    height: 1px;
    background: #bebebe;
  } */
  @media only screen and (max-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 12%;
    margin-bottom: 55px;
    &:not(:nth-child(5))::after {
      top: 99%;
      left: 27.5%;
      width: 1.5px;
      height: 70%;
    }
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    &:not(:nth-child(5))::after {
      top: 32%;
    }
  }

  @media only screen and (min-width: 1100px) and (max-width: 1366px) {
    &:not(:nth-child(5))::after {
      top: 45%;
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
    &:not(:nth-child(5))::after {
      top: 32%;
    }
  }
`

const StepImage = styled.div`
  width: 45%;
  margin-bottom: 10px;
  @media only screen and (max-width: 800px) {
    width: 15%;
    margin-bottom: 0;
  }
`

const Step = styled.p`
  position: relative;
  font-size: ${size.SSM};
  width: 3.5vw;
  height: 3.5vw;
  border: 1px solid #bebebe;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  &:not(:nth-child(5))::after {
    content: "";
    position: absolute;
    top: calc(50% - 0.5px);
    right: -425%;
    width: 400%;
    height: 1px;
    background: #bebebe;
  }

  @media only screen and (max-width: 800px) {
    width: 45px;
    height: 45px;
    margin-bottom: 0;
  }
`

const StepText = styled.p`
  font-size: ${size.XS};
  line-height: 1.2rem;
  letter-spacing: 0.8px;
  text-align: center;
  width: 100%;
  font-family: ${family.Alata};
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 17px;
    width: 60%;
    margin: 0;
  }
`

const IdeaContainer = styled.div`
  width: 98%;
  border: 1px solid black;
  padding: 10px 35px;
  margin-top: 6vh;
  z-index: 2;
  ${props =>
    props.blue &&
    `
    color: white;
    border: 1px solid white;
    margin: 0;
    width: 100%;
  `}
`

const BlueContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  padding: 8px;
  background: ${colors.darkBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media only screen and (max-width: 800px) {
    z-index: 5;
  }
`

const IdeaTitle = styled.p`
  font-size: ${size.M};
  margin: 0;
  text-align: center;
  font-family: ${props => (props.blue ? family.Archivo : family.Alata)};
  margin-bottom: 20px;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 27px;
  }
`

const IdeaText = styled.p`
  font-size: ${size.XXS};
  line-height: 1.2rem;
  width: ${props => (props.blue ? "95%" : "23%")};
  margin: 0;
  text-align: center;
  margin-bottom: 10px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 21px;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    padding-top: 30px;
  }
`

const IdeaImage = styled.div`
  height: 50px;
  margin-bottom: 0;
  @media only screen and (max-width: 800px) {
    height: 70px;
    margin-bottom: 20px;
  }
`

const ContainerGC = styled.div`
  width: 110%;
  margin-top: 10vh;
  z-index: 2;
  @media only screen and (max-width: 800px) {
    overflow-x: scroll;
    width: calc(100% + 12vw);
    z-index: 5;
  }
`

const RowContainerGC = styled(Row)`
  margin-top: 0;
  width: 95%;
  @media only screen and (max-width: 800px) {
    width: 400%;
    padding-right: 6vw;
    margin-bottom: 20px;
  }
`

const RowGC = styled(Row)`
  position: relative;
  height: 70px;
  align-items: center;
  justify-content: space-around;
  &::after {
    content: "";
    position: absolute;
    top: 48%;
    left: -10%;
    background: #bebebe;
    height: 1px;
    width: 110%;
  }
  @media only screen and (max-width: 800px) {
    height: 45px;
    &::after {
      left: -8%;
      width: 105%;
    }
  }
`

const StepGC = styled(Step)`
  font-family: ${family.Alata};
  background: #ffe8db;
  z-index: 10;
  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
    margin-top: 15px;
  }
`

const PointGC = styled.div`
  width: 18px;
  height: 18px;
  background: #dff7ff;
  border-radius: 50%;
  border: 1px solid #c9c9c9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 70%;
    width: fit-content;
    max-width: 10vw;
    text-align: center;
    transform: translateX(-50%);
    font-size: 0.5rem;
    line-height: 0.8rem;
  }
  @media only screen and (max-width: 800px) {
    &::after {
      max-width: 100px;
      transform: translateX(-40%);
    }
  }
`

const RowBlue = styled(Row)`
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

const ColumnBlue = styled(Column)`
  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-top: 40px;
  }
`

const ColumnGC = styled(Column)`
  @media only screen and (max-width: 800px) {
    width: 15%;
  }
`

const RowCadre = styled(Row)`
  z-index: 2;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const InterLink = styled(AnchorLink)`
  ${props =>
    props.little
      ? `font-size: ${size.XXS}; padding: 0px 20px;`
      : `font-size: ${size.XS}; padding: 2px 50px;`}
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  text-decoration: none;
  position: relative;
  background: ${props => (props.background ? props.background : "white")};
  margin-top: 40px;
  z-index: 50;
  transition: 0.3s ease;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 25px;
    padding: 5px 30px;
    font-size: 0.8rem;
  }
`

const ImgLink = styled.a`
  display: contents;
`
const ImgLinkAnchor = styled(AnchorLink)`
  display: contents;
`

const LinkBack = styled.a`
  ${props =>
    props.little
      ? `font-size: ${size.XXS}; padding: 0px 20px;`
      : `font-size: ${size.XS}; padding: 2px 50px;`}
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  position: relative;
  background: ${props => (props.background ? props.background : "white")};
  margin-top: 40px;
  z-index: 50;
  transition: 0.3s ease;
  text-decoration: none;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    background: ${props =>
      props.noBackground ? "transparent" : props.background};
  }
`

const PageOffre = ({ data, path }) => {
  const { isMobile } = useContext(AppContext)
  const prismic = data.prismicOffre.data

  const handleScroll = () => {
    // logic for the table scrolling and progress bar
    const $progressBar = document.querySelector(".progress-bar")
    const $table = document.querySelector(".table")
    const $tableInner = document.querySelector(".table div")

    if (!$progressBar || !$table || !$tableInner) return

    const percentage =
      ($table.scrollLeft /
        ($tableInner.getBoundingClientRect().width -
          $table.getBoundingClientRect().width)) *
      100
    const scrollLeft =
      (percentage * $progressBar.parentNode.getBoundingClientRect().width) /
        100 -
      10
    $progressBar.style.transform = `translateY(-50%) translateX(${scrollLeft}px)`
  }

  return (
    <Layout
      pageValueMenu={1}
      headerString={[
        "Nos offres",
        "Digital Beginner",
        "Digital Intermediate",
        "Digital Advanced",
        "Grand Compte",
        "",
      ]}
      hasScrolled={true}
      footerText={prismic.footer_quote.text}
    >
      <SEO
        title={prismic.page_title.text}
        description={prismic.page_description.text}
        image={prismic.og_image.url}
        location={path}
      />
      <MainContainer>
        {isMobile ? (
          <svg
            className="bulle-mobile-droite"
            style={{ position: "absolute", right: "0", top: "1%" }}
            width="344"
            height="668"
            viewBox="0 0 344 668"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M245.896 1.07582C296.88 -3.06819 354.077 3.27806 390.664 46.5487C426.134 88.4979 409.868 160.565 428.026 216.475C448.119 278.34 511.945 323.806 501.944 389.184C492.039 453.933 422.807 472.143 382.225 515.885C335.313 566.451 307.721 652.114 245.896 665.173C182.351 678.595 110.139 643.141 70.0787 581.817C31.9875 523.508 62.7779 438.228 50.0906 365.439C39.4425 304.349 -10.9648 252.57 2.16679 192.195C15.3726 131.48 71.0968 103.079 114.569 68.99C155.536 36.8656 197.224 5.03194 245.896 1.07582Z"
              fill="#F5FAFF"
            />
          </svg>
        ) : (
          <BackgroundBlob />
        )}
        <Container>
          <Title>
            {prismic.title_black.text}{" "}
            <BlueText>{prismic.title_blue.text}</BlueText>{" "}
            {prismic.title_black_2.text}{" "}
            <BlueText>{prismic.title_blue_2.text}</BlueText>
          </Title>
          <Text>
            {prismic.subtitle.raw.map((subtitle, index) => (
              <span key={index}>
                {subtitle.text}
                {!isMobile && <br />}
              </span>
            ))}
          </Text>
        </Container>
        <TableContainer onScroll={handleScroll} className="table">
          <OfferTable className="changeHeaderString">
            <Row>
              <Column>
                <ImgLinkAnchor className="click" to="/offre#beginner">
                  <TableImg
                    className="Tilt"
                    options={{ max: 35, perspective: 300 }}
                  >
                    <Img
                      loading="eager"
                      fluid={data.oeuf.childImageSharp.fluid}
                    />
                  </TableImg>
                </ImgLinkAnchor>
                <TableTitle>
                  {prismic.body[0].items[0].column_title.text}
                </TableTitle>
              </Column>
              <Column>
                <ImgLinkAnchor className="click" to="/offre#intermediate">
                  <TableImg
                    className="Tilt"
                    options={{ max: 35, perspective: 300 }}
                  >
                    <Img
                      loading="eager"
                      fluid={data.poussin.childImageSharp.fluid}
                    />
                  </TableImg>
                </ImgLinkAnchor>
                <TableTitle>
                  {prismic.body[0].items[1].column_title.text}
                </TableTitle>
              </Column>
              <Column>
                <ImgLinkAnchor className="click" to="/offre#advanced">
                  <TableImg
                    className="Tilt"
                    options={{ max: 35, perspective: 300 }}
                  >
                    <Img
                      loading="eager"
                      fluid={data.cygne.childImageSharp.fluid}
                    />
                  </TableImg>
                </ImgLinkAnchor>
                <TableTitle>
                  {prismic.body[0].items[2].column_title.text}
                </TableTitle>
              </Column>
              <Column>
                <ImgLinkAnchor className="click" to="/offre#grands-comptes">
                  <TableImg
                    className="Tilt"
                    options={{ max: 35, perspective: 300 }}
                  >
                    <Img
                      loading="eager"
                      fluid={data.aigle.childImageSharp.fluid}
                    />
                  </TableImg>
                </ImgLinkAnchor>
                <TableTitle>
                  {prismic.body[0].items[3].column_title.text}
                </TableTitle>
              </Column>
            </Row>
            <Row full>
              <RowTitle>Croissance</RowTitle>
            </Row>
            <Row className="content">
              {prismic.body[0].items.map((item, index) => (
                <TableText key={index}>
                  {item.croissance_row.raw.map((row, index) => (
                    <span key={index}>
                      {row.text}
                      <br />
                    </span>
                  ))}
                </TableText>
              ))}
            </Row>
            <Row full>
              <RowTitle>Audience</RowTitle>
            </Row>
            <Row className="content">
              {prismic.body[0].items.map((item, index) => (
                <TableText key={index}>
                  {item.audience_row.raw.map((row, index) => (
                    <span key={index}>
                      {row.text}
                      <br />
                    </span>
                  ))}
                </TableText>
              ))}
            </Row>
            <Row full>
              <RowTitle>Acquisition</RowTitle>
            </Row>
            <Row className="content">
              {prismic.body[0].items.map((item, index) => (
                <TableText key={index}>
                  {item.acquisition_row.raw.map((row, index) => (
                    <span key={index}>
                      {row.text}
                      <br />
                    </span>
                  ))}
                </TableText>
              ))}
            </Row>
            <Row full>
              <RowTitle>Website</RowTitle>
            </Row>
            <Row className="content">
              {prismic.body[0].items.map((item, index) => (
                <TableText key={index}>
                  {item.website_row.raw.map((row, index) => (
                    <span key={index}>
                      {row.text}
                      <br />
                    </span>
                  ))}
                </TableText>
              ))}
            </Row>
            <Row full>
              <RowTitle>Contents</RowTitle>
            </Row>
            <Row className="content">
              {prismic.body[0].items.map((item, index) => (
                <TableText key={index}>
                  {item.contents_row.raw.map((row, index) => (
                    <span key={index}>
                      {row.text}
                      <br />
                    </span>
                  ))}
                </TableText>
              ))}
            </Row>
            <Row>
              <Column>
                <InterLink className="click" to="/offre#beginner" little>
                  voir l'offre
                </InterLink>
              </Column>
              <Column>
                <InterLink className="click" to="/offre#intermediate" little>
                  voir l'offre
                </InterLink>
              </Column>
              <Column>
                <InterLink className="click" to="/offre#advanced" little>
                  voir l'offre
                </InterLink>
              </Column>
              <Column>
                <InterLink className="click" to="/offre#grands-comptes" little>
                  voir l'offre
                </InterLink>
              </Column>
            </Row>
          </OfferTable>
        </TableContainer>
        {isMobile && (
          <ProgressBar>
            <Circle className="progress-bar"></Circle>
          </ProgressBar>
        )}
        <OfferDetail
          className="changeHeaderString"
          marginTop
          background="#E6FFFE"
          id="beginner"
        >
          {isMobile && (
            <SectionTitle>
              01.
              <br />
              {prismic.body[1].primary.mobile_section_title.text}
            </SectionTitle>
          )}
          <RowCadre center={!isMobile}>
            <ImgLink
              className="click"
              href={prismic.body[1].primary.link_infos.url}
            >
              <ImageEau
                className="Tilt"
                options={{ max: 35, perspective: 1000 }}
              >
                <Img loading="eager" fluid={data.oeuf.childImageSharp.fluid} />
              </ImageEau>
            </ImgLink>
            <Cadre
              id="cadre-oeuf"
              text1={prismic.body[1].primary.cadre_quote.text}
              text2={{
                titre: prismic.body[1].primary.cadre_title.text,
                point1: prismic.body[1].primary.cadre_1.text,
                point2: prismic.body[1].primary.cadre_2.text,
                point3: prismic.body[1].primary.cadre_3.text,
              }}
              location={path}
            />
          </RowCadre>
          <StepContainer>
            {prismic.body[1].items.map((step, index) => (
              <StepColumn key={index}>
                <StepImage>
                  <Img
                    loading="eager"
                    fluid={step.step_logo.localFile.childImageSharp.fluid}
                  />
                </StepImage>
                <Step
                  style={{ marginTop: index > 2 ? "4px" : 0 }}
                  className={`${index === 4 || isMobile ? "no-after" : ""}`}
                >
                  {index + 1}
                </Step>
                <StepText>{step.description_partie.text}</StepText>
              </StepColumn>
            ))}
          </StepContainer>
          {!isMobile && (
            <IdeaContainer>
              <IdeaTitle>
                {prismic.body[1].primary.fixed_cadre_title.text}
              </IdeaTitle>
              <Row full>
                <IdeaText>{prismic.body[1].primary.cadre_text_1.text}</IdeaText>
                <IdeaText>{prismic.body[1].primary.cadre_text_2.text}</IdeaText>
                <IdeaText>{prismic.body[1].primary.cadre_text_3.text}</IdeaText>
                <IdeaText>{prismic.body[1].primary.cadre_text_4.text}</IdeaText>
              </Row>
            </IdeaContainer>
          )}
          <LinkBack
            href={prismic.body[1].primary.link_infos.url}
            className="click"
            background="#E6FFFE"
            noBackground
          >
            {prismic.body[1].primary.link_infos_title.text}
          </LinkBack>
        </OfferDetail>
        <OfferDetail
          className="changeHeaderString"
          background="#FFF9DB"
          id="intermediate"
        >
          {isMobile && (
            <SectionTitle>
              02.
              <br />
              {prismic.body[2].items[0].section_title_mobile.text}
            </SectionTitle>
          )}
          <Bubbles />
          <RowCadre className={!isMobile && "trigger"} center={!isMobile}>
            <ImgLink
              className="click"
              href={prismic.body[2].items[0].link_infos.url}
            >
              <ImageEau
                className="Tilt"
                options={{ max: 35, perspective: 1000 }}
              >
                <Img
                  loading="eager"
                  fluid={data.poussin.childImageSharp.fluid}
                />
              </ImageEau>
            </ImgLink>
            <Cadre
              id="cadre-poussin"
              text1={prismic.body[2].items[0].cadre_quote.text}
              text2={{
                titre: prismic.body[2].items[0].cadre_title.text,
                point1: prismic.body[2].items[0].cadre_text_1.text,
                point2: prismic.body[2].items[0].cadre_text_2.text,
                point3: prismic.body[2].items[0].cadre_text_3.text,
              }}
              location={path}
            />
          </RowCadre>
          <MediumArticles
            stretch={true}
            className={isMobile && "trigger"}
            articles={[
              {
                title: prismic.body[2].items[0].article_title_1.text,
                link: prismic.body[2].items[0].article_link_1.url,
              },
              {
                title: prismic.body[2].items[0].article_title_2.text,
                link: prismic.body[2].items[0].article_link_2.url,
              },
              {
                title: prismic.body[2].items[0].article_title_3.text,
                link: prismic.body[2].items[0].article_link_3.url,
              },
              {
                title: prismic.body[2].items[0].article_title_4.text,
                link: prismic.body[2].items[0].article_link_4.url,
              },
              {
                title: prismic.body[2].items[0].article_title_5.text,
                link: prismic.body[2].items[0].article_link_5.url,
              },
            ]}
            circleColor="#FFE8DB"
          />
          <LinkBack
            href={prismic.body[2].items[0].link_infos.url}
            className="click"
            background="#FFF9DB"
            noBackground
          >
            {prismic.body[2].items[0].title_infos_link.text}
          </LinkBack>
        </OfferDetail>
        <OfferDetail
          className="changeHeaderString"
          background="#F5FAFF"
          id="advanced"
        >
          {isMobile && (
            <SectionTitle>
              03.
              <br />
              {prismic.body[2].items[1].section_title_mobile.text}
            </SectionTitle>
          )}
          <RowCadre center={!isMobile}>
            <ImgLink
              className="click"
              href={prismic.body[2].items[1].link_infos.url}
            >
              <ImageEau
                className="Tilt"
                options={{ max: 35, perspective: 1000 }}
              >
                <Img loading="eager" fluid={data.cygne.childImageSharp.fluid} />
              </ImageEau>
            </ImgLink>
            <Cadre
              id="cadre-cygne"
              text1={prismic.body[2].items[1].cadre_quote.text}
              text2={{
                titre: prismic.body[2].items[1].cadre_title.text,
                point1: prismic.body[2].items[1].cadre_text_1.text,
                point2: prismic.body[2].items[1].cadre_text_2.text,
                point3: prismic.body[2].items[1].cadre_text_3.text,
              }}
              location={path}
            />
          </RowCadre>
          <MediumArticles
            margin={true}
            articles={[
              {
                title: prismic.body[2].items[1].article_title_1.text,
                link: prismic.body[2].items[1].article_link_1.url,
              },
              {
                title: prismic.body[2].items[1].article_title_2.text,
                link: prismic.body[2].items[1].article_link_2.url,
              },
              {
                title: prismic.body[2].items[1].article_title_3.text,
                link: prismic.body[2].items[1].article_link_3.url,
              },
              {
                title: prismic.body[2].items[1].article_title_4.text,
                link: prismic.body[2].items[1].article_link_4.url,
              },
              {
                title: prismic.body[2].items[1].article_title_5.text,
                link: prismic.body[2].items[1].article_link_5.url,
              },
            ]}
            circleColor="#FFF9DB"
          />
          <LinkBack
            href={prismic.body[2].items[1].link_infos.url}
            className="click"
            background="#F5FAFF"
            noBackground
          >
            {prismic.body[2].items[1].title_infos_link.text}
          </LinkBack>
        </OfferDetail>
        <OfferDetail
          className="changeHeaderString"
          background="#FFE8DB"
          id="grands-comptes"
        >
          {isMobile && (
            <SectionTitle>
              04.
              <br />
              {prismic.body[2].items[2].section_title_mobile.text}
            </SectionTitle>
          )}
          <RowCadre center={!isMobile}>
            <ImgLink
              className="click"
              href={prismic.body[2].items[2].link_infos.url}
            >
              <ImageEau
                className="Tilt"
                options={{ max: 35, perspective: 1000 }}
              >
                <Img loading="eager" fluid={data.aigle.childImageSharp.fluid} />
              </ImageEau>
            </ImgLink>
            <Cadre
              id="cadre-aigle"
              text1={prismic.body[2].items[2].cadre_quote.text}
              text2={{
                titre: prismic.body[2].items[2].cadre_title.text,
                point1: prismic.body[2].items[2].cadre_text_1.text,
                point2: prismic.body[2].items[2].cadre_text_2.text,
                point3: prismic.body[2].items[2].cadre_text_3.text,
              }}
              location={path}
            />
          </RowCadre>
          <MediumArticles
            margin={true}
            articles={[
              {
                title: prismic.body[2].items[2].article_title_1.text,
                link: prismic.body[2].items[2].article_link_1.url,
              },
              {
                title: prismic.body[2].items[2].article_title_2.text,
                link: prismic.body[2].items[2].article_link_2.url,
              },
              {
                title: prismic.body[2].items[2].article_title_3.text,
                link: prismic.body[2].items[2].article_link_3.url,
              },
              {
                title: prismic.body[2].items[2].article_title_4.text,
                link: prismic.body[2].items[2].article_link_4.url,
              },
              {
                title: prismic.body[2].items[2].article_title_5.text,
                link: prismic.body[2].items[2].article_link_5.url,
              },
            ]}
            circleColor="#F5FAFF"
          />
          <LinkBack
            href={prismic.body[2].items[2].link_infos.url}
            className="click"
            background="#FFE8DB"
            noBackground
          >
            {prismic.body[2].items[2].title_infos_link.text}
          </LinkBack>
          <ContainerGC>
            <RowContainerGC>
              <ColumnGC>
                <StepGC className="no-after">A</StepGC>
                <StepText style={{ width: "auto" }}>
                  {prismic.title_step_a.text}
                </StepText>
              </ColumnGC>
              <RowGC>
                {prismic.steps1.map((step, index) => (
                  <PointGC key={index} data-text={step.step_text.text} />
                ))}
              </RowGC>
            </RowContainerGC>
            <RowContainerGC>
              <ColumnGC>
                <StepGC className="no-after">B</StepGC>
                <StepText style={{ width: "auto" }}>
                  {prismic.title_step_b.text}
                </StepText>
              </ColumnGC>
              <RowGC>
                {prismic.steps2.map((step, index) => (
                  <PointGC key={index} data-text={step.step_text.text} />
                ))}
              </RowGC>
            </RowContainerGC>
            <RowContainerGC>
              <ColumnGC>
                <StepGC className="no-after">C</StepGC>
                <StepText style={{ width: "auto" }}>
                  {prismic.title_step_c.text}
                </StepText>
              </ColumnGC>
              <RowGC>
                {prismic.steps3.map((step, index) => (
                  <PointGC key={index} data-text={step.step_text.text} />
                ))}
              </RowGC>
            </RowContainerGC>
          </ContainerGC>
          <BlueContainer>
            <IdeaContainer blue>
              <IdeaTitle blue>{prismic.blue_cadre_title.text}</IdeaTitle>
              <RowBlue full>
                <ColumnBlue>
                  <IdeaImage style={{ width: isMobile ? "80px" : "50px" }}>
                    <Img
                      loading="eager"
                      fluid={
                        prismic.blue_cadre_parts[0].part_logo.localFile
                          .childImageSharp.fluid
                      }
                    />
                  </IdeaImage>
                  <IdeaText blue>
                    {prismic.blue_cadre_parts[0].part_content.text}
                  </IdeaText>
                </ColumnBlue>
                <ColumnBlue>
                  <IdeaImage style={{ width: isMobile ? "80px" : "50px" }}>
                    <Img
                      loading="eager"
                      fluid={
                        prismic.blue_cadre_parts[1].part_logo.localFile
                          .childImageSharp.fluid
                      }
                    />
                  </IdeaImage>
                  <IdeaText blue>
                    {prismic.blue_cadre_parts[1].part_content.text}
                  </IdeaText>
                </ColumnBlue>
                <ColumnBlue>
                  <IdeaImage style={{ width: isMobile ? "80px" : "50px" }}>
                    <Img
                      loading="eager"
                      fluid={
                        prismic.blue_cadre_parts[2].part_logo.localFile
                          .childImageSharp.fluid
                      }
                    />
                  </IdeaImage>
                  <IdeaText blue>
                    {prismic.blue_cadre_parts[2].part_content.text}
                  </IdeaText>
                </ColumnBlue>
                <ColumnBlue>
                  <IdeaImage style={{ width: isMobile ? "80px" : "50px" }}>
                    <Img
                      loading="eager"
                      fluid={
                        prismic.blue_cadre_parts[3].part_logo.localFile
                          .childImageSharp.fluid
                      }
                    />
                  </IdeaImage>
                  <IdeaText blue>
                    {prismic.blue_cadre_parts[3].part_content.text}
                  </IdeaText>
                </ColumnBlue>
              </RowBlue>
            </IdeaContainer>
          </BlueContainer>
        </OfferDetail>
      </MainContainer>
    </Layout>
  )
}

export default PageOffre

export const query = graphql`
  query {
    oeuf: file(relativePath: { eq: "oeuf.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    poussin: file(relativePath: { eq: "poussin.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aigle: file(relativePath: { eq: "aigle.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cygne: file(relativePath: { eq: "cygne.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prismicOffre {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        og_image {
          url
        }
        blue_cadre_parts {
          part_logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 100) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          part_content {
            text
          }
        }
        blue_cadre_title {
          text
        }
        footer_quote {
          text
        }
        steps1 {
          step_text {
            text
          }
        }
        steps2 {
          step_text {
            text
          }
        }
        steps3 {
          step_text {
            text
          }
        }
        subtitle {
          raw
        }
        title_black {
          text
        }
        title_black_2 {
          text
        }
        title_blue {
          text
        }
        title_blue_2 {
          text
        }
        title_step_a {
          text
        }
        title_step_b {
          text
        }
        title_step_c {
          text
        }
        body {
          ... on PrismicOffreBodyTableauOffre {
            items {
              acquisition_row {
                raw
              }
              audience_row {
                raw
              }
              column_title {
                text
              }
              contents_row {
                raw
              }
              croissance_row {
                raw
              }
              website_row {
                raw
              }
            }
          }
          ... on PrismicOffreBodySectionBeginner {
            items {
              description_partie {
                text
              }
              step_logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                url
              }
            }
            primary {
              cadre_1 {
                text
              }
              cadre_2 {
                text
              }
              cadre_3 {
                text
              }
              cadre_quote {
                text
              }
              cadre_text_1 {
                text
              }
              cadre_text_2 {
                text
              }
              cadre_text_3 {
                text
              }
              cadre_text_4 {
                text
              }
              cadre_title {
                text
              }
              fixed_cadre_title {
                text
              }
              mobile_section_title {
                text
              }
              link_infos {
                url
              }
              link_infos_title {
                text
              }
            }
          }
          ... on PrismicOffreBodySectionCadrePlusArticle {
            items {
              article_link_1 {
                url
              }
              article_link_2 {
                url
              }
              article_link_3 {
                url
              }
              article_link_4 {
                url
              }
              article_link_5 {
                url
              }
              article_title_1 {
                text
              }
              article_title_2 {
                text
              }
              article_title_3 {
                text
              }
              article_title_4 {
                text
              }
              article_title_5 {
                text
              }
              cadre_quote {
                text
              }
              cadre_text_1 {
                text
              }
              cadre_text_2 {
                text
              }
              cadre_text_3 {
                text
              }
              cadre_title {
                text
              }
              section_title_mobile {
                text
              }
              link_infos {
                url
              }
              title_infos_link {
                text
              }
            }
          }
        }
      }
    }
  }
`
